// @import "~bootstrap/scss/bootstrap";
@import "./styles/bootstrap/bootstrap.scss";
@import "~bootstrap-icons/font/bootstrap-icons";
@import "react-datepicker/dist/react-datepicker.css";

@import "./utils/fonts/Montserrat/stylesheet.css";

* {
    font-family: 'Montserrat', "Helvetica Neue", Arial, sans-serif;
}

// body {
//     max-width: 1920px;
//     margin: 0 auto;
// }